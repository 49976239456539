@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC&family=Source+Sans+Pro:wght@400;700&family=Tajawal&family=Ubuntu+Mono:wght@400;700&display=swap");

body {
  background-color: #25282a;
  font-family: "Source Sans Pro", "Tajawal", "Noto Sans SC";
  overflow: hidden;
  transition: all 0.2s;
}

body::-webkit-scrollbar {
  display: none;
}

::selection {
  background: #51c85b;
  color: #ffffff;
}

button:hover {
  cursor: pointer;
}

.App {
  text-align: center;
  max-width: 728px;
  margin: 0 auto;
  border-radius: 25px;
  transition: all 0.2s;
}

.App header {
  background-color: #181717;
  height: 10vh;
  min-height: 50px;
  color: #e7e7e7;
  position: fixed;
  width: 100%;
  max-width: 728px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  box-sizing: border-box;
  border-radius: 10px;
}

img.avatar {
  background-color: #2f2f35;
}

img.message {
  max-width: 100px;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: #212024;
  border-radius: 25px;
}

#title {
  font-weight: 700;
  font-size: 160%;
}

#roomId {
  font-size: 130%;
  color: #4e4e5d;
  max-width: 10rem;
  text-overflow: clip;
}

.roomTitle {
  padding: 30px;
  line-height: 5%;
  text-align: left;
}

main {
  padding: 10px;
  height: 77.6vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
}

main::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.5rem;
}

main::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-right: 10px;
}

main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  margin-right: 10px;
  background-color: #998ac748;
}

button {
  background-color: #00000000;
  color: white;
}

form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: #1b1b1f;
  width: 100%;
  max-width: 728px;
  display: flex;
  font-size: 1.5rem;
  padding: 0px;
  margin: 0px;
  border-radius: 25px;
}

form button {
  background-color: #2d346d;
  transition: all 0.2s;
  margin: 10px 10px 10px 5px;
  border-radius: 25px;
  vertical-align: text-bottom;
  height: auto;
}

form button:hover {
  background-color: #353e81;
}

#textInput {
  box-sizing: border-box;
  line-height: 1.5;
  width: 90%;
  font-size: 1.5rem;
  text-align: left;
  background: #252529;
  color: #8f8f8f;
  outline: none;
  border: none;
  padding: 0 10px;
  padding-right: 25px;
  padding-left: 25px;
  font-family: "Ubuntu Mono", "Source Sans Pro";
  transition: all 0.2s;
  border-radius: 25px;
  margin: 10px 5px 10px 10px;
}

#textInput:hover,
#textInput:focus {
  color: #ffffff;
  background: #2a2a2e;
  letter-spacing: 0.5px;
}

#textInput.limitReached {
  background-color: #442323;
  border: 3px solid red;
}

#textInput:disabled {
  cursor: not-allowed;
}

#textInput:disabled:hover {
  background: #2f2f2f;
  color: #8f8f8f;
  letter-spacing: normal;
}

.submit-button {
  background-color: #2d346d;
  border: none;
  color: #e7e7e7;
  padding: 0px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.25rem;
  font-family: "Ubuntu Mono", "Source Sans Pro";
}

button {
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.25rem;
  font-family: "Ubuntu Mono", "Source Sans Pro";
  height: 75%;
  text-align: center;
}

button:disabled {
  opacity: 0.5;
  background-color: #2e356e;
  cursor: not-allowed;
}

button:disabled:hover {
  opacity: 0.6;
  background-color: #303874;
  cursor: not-allowed !important;
}

.sign-in-google {
  color: #e7e7e7;
  max-width: 300px;
  width: 60%;
  margin: 0 auto;
  background: url("./assets/img/wallpaper.png");
  background-position: center;
  background-size: 100%;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  transition: all 0.2s;
  padding: 12px;
}

.sign-in-google:hover,
.sign-in-google:focus {
  background-size: 120%;
}

.sign-in-twitter {
  color: #e7e7e7;
  max-width: 300px;
  width: 60%;
  margin: 0 auto;
  background-color: #1da1f2;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  transition: all 0.2s;
  padding: 12px;
}

.sign-in-twitter:hover {
  background-color: #197ebd;
}

.sign-in-github {
  color: #e7e7e7;
  max-width: 300px;
  width: 60%;
  margin: 0 auto;
  background-color: #161616;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  transition: all 0.2s;
  padding: 12px;
}

.sign-in-github:hover {
  background-color: #0a0a0a;
}

.sign-in-anon {
  color: #e7e7e7;
  max-width: 300px;
  width: 60%;
  margin: 0 auto;
  background-color: #272727;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  transition: all 0.2s;
  padding: 12px;
}

.sign-in-anon:hover {
  background-color: #222222;
}

.sign-out {
  background-color: #282531;
  border: none;
  color: #e7e7e7;
  padding: 0px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.25rem;
  font-family: "Ubuntu Mono", "Source Sans Pro";
  min-width: 3rem;
  height: 75%;
  text-align: center;
  transition: all 0.2s;
  border-radius: 25px;
  margin-right: 15px;
}

.sign-out:hover {
  background-color: #1d1d24;
}

ul,
li {
  text-align: left;
  list-style: none;
}

.sent a {
  color: #e7e7e7;
  text-decoration: none;
}

.received a {
  color: #e7e7e7;
  text-decoration: none;
}

a:link:hover {
  text-decoration: underline;
}

.messageText {
  max-width: 400px;
  min-width: none;
  margin: 1px;
  margin-top: 5px;
  margin-bottom: 0px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  color: #e7e7e7;
  word-wrap: break-word;
  text-align: left;
  top: -7px;
}

.messageText img {
  display: flex;
  max-width: 200px;
  border-radius: 10%;
  margin: 5px 0px 0px 0px;
}

.message {
  display: flex;
  align-items: center;
}

.sent {
  flex-direction: row-reverse;
}

.sent .messageText {
  color: #e7e7e7;
  background: #503bc7;
  border-radius: 25px 25px 0px 25px;
  align-self: flex-end;
}

.received .messageText {
  background: #2f2f35;
  color: #e7e7e7;
  border-radius: 25px 25px 25px 0px;
}

.avatar {
  color: white;
  font-weight: bold;
  font-size: x-large;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
  box-shadow: 0px 3px 15px #1b1b1b;
}

.avatar:hover {
  border-radius: 35%;
}

#helpLink {
  color: #646479;
  text-decoration: none;
  transition: all 0.2s;
}

#helpLink:hover {
  color: #a3a3c0;
  letter-spacing: 0.1rem;
}

.timestamp {
  color: #8e88a3;
  margin-top: -5px;
  margin-bottom: 15px;
}

.sent .timestamp {
  text-align: right;
  margin-right: 5rem;
}

.received .timestamp {
  text-align: left;
  margin-left: 3.2rem;
}

.messageText .emoji {
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: text-bottom;
}

.emojilette {
  content: url("assets/img/emojicon.svg");
  width: 30px;
  background: #2f2f2f;
  background-size: 100px;
  margin: 10px;
  margin: none;
  vertical-align: middle;
}

svg {
  vertical-align: text-bottom;
}

.deleteBtn {
  background: #292c2e;
  border: none;
  border-radius: 25px;
  color: #e7e7e7;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.25rem;
  font-family: "Ubuntu Mono", "Source Sans Pro";
}

.received .deleteBtn {
  display: none;
}

.settings {
  background-color: #131313;
  height: 45px;
  width: 45px;
  border-radius: 25px;
}

.menu {
  position: relative;
  display: inline-block;
  justify-content: center;
  margin: 20px;
}

.menu-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: #27262c;
  max-width: 300px;
  z-index: 1;
  border-radius: 20px;
  transition: all 0.2s;
  right: 10px;
}

.menu-content button {
  color: #e7e7e7;
  background-color: #27262c;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
  transition: all 0.05s;
  border-radius: 25px;
  align-items: center;
  min-width: 200px;
  justify-content: center;
}

.menu-content button:hover {
  background-color: #3a3842;
}

.menu:hover .menu-content {
  opacity: 1;
  visibility: visible;
}

.Toastify__toast-body {
  color: #e7e7e7;
  font-family: "Source Sans Pro";
  font-size: 1.1rem;
}

.tooltip {
  font-family: "Ubuntu Mono";
  border-radius: 25px;
}

code {
  font-family: "Ubuntu Mono";
  font-size: 1rem;
}

.line {
  margin: 0px 7px 0px 7px;
  color: #6b6b74;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.title-card {
  padding: 2rem;
  background-color: #4040446e;
  border-width: 10px;
  border-style: dashed;
  border-color: #7a7a816e;
  border-radius: 3rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.title-card h1 {
  margin: 0.3rem;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.title-card p {
  margin: 0.3rem;
  color: #9393a7;
}

.title-card a:any-link {
  color: #a4a4bd;
  text-decoration: none;
}

.title-card a:any-link:hover {
  color: white;
  text-decoration: underline;
}

.secret-img {
  color: #ffffff;
  opacity: 30%;
  font-size: 100px;
}

.tux {
  display: none;
}

.page-content {
  text-align: left;
  padding: 3rem;
  color: white;
}

@media only screen and (max-width: 728px) {
  .App header {
    border-radius: 0 0 25px 25px;
  }

  form {
    border-radius: 25px 25px 0 0;
    height: 15vh;
  }
}
